import { Grid, Typography, useTheme, Button } from '@mui/material';
import { useTranslations } from 'hooks/useTranslations';
import DropdownInput from 'components/dropdownInput/dropdownInput';
import { useForm, useWatch } from 'react-hook-form';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ResourcesItem from './resourcesItem';
import { ParticipantDocumentType } from 'hooks/services/hosts/interfaces/participantDocumentType';
import { useHostService } from 'hooks/services/hosts';
import { downloadBlob } from 'utils/file/fileUtilities';

interface FormData {
  recourceType: keyof typeof ParticipantDocumentType | null;
}

const Resources = ({
  id,
  hostId,
  isMobile,
  isResume,
  isApplicationResume,
  isJobOffer,
  isSignedPlacementPlan
}: {
  id: number;
  hostId: number;
  isMobile?: boolean;
  isResume?: boolean;
  isApplicationResume?: boolean;
  isJobOffer?: boolean;
  isSignedPlacementPlan?: boolean;
}) => {
  const theme = useTheme();
  const { getParticipantDocument } = useHostService();

  const { uploadDocument, participants } = useTranslations();
  const {
    resourcesLabel,
    resumeLabel,
    applicationResumeLabel,
    signedJobOfferLabel,
    chooseDocument,
    signedPlacementPlan
  } = participants.modal;

  const { control, register } = useForm<FormData>({
    defaultValues: {
      recourceType: null,
    },
  });

  const recourceTypes: {
    key: keyof typeof ParticipantDocumentType,
    id: keyof typeof ParticipantDocumentType,
    value: string,
    disabled: boolean;
  }[] = [
      {
        key: ParticipantDocumentType.Resume,
        id: ParticipantDocumentType.Resume,
        value: resumeLabel,
        disabled: !isResume
      },
      {
        key: ParticipantDocumentType.ApplicationResume,
        id: ParticipantDocumentType.ApplicationResume,
        value: applicationResumeLabel,
        disabled: !isApplicationResume
      },
      {
        key: ParticipantDocumentType.JobOffer,
        id: ParticipantDocumentType.JobOffer,
        value: signedJobOfferLabel,
        disabled: !isJobOffer
      },
      {
        key: ParticipantDocumentType.SignedPlacementPlan,
        id: ParticipantDocumentType.SignedPlacementPlan,
        value: signedPlacementPlan,
        disabled: !isSignedPlacementPlan
      },
    ];


  const recourceTypeValue = useWatch({
    control,
    name: 'recourceType',
  });

  const downloadDocument = async (key: keyof typeof ParticipantDocumentType | null) => {
    if (key) {
      try {
        const blob = await getParticipantDocument(hostId, id, key);;
        const fileName = `${key}${id}.pdf`;
        downloadBlob(blob, fileName);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <Grid container mt={theme.spacing(1)}>
      <Typography
        variant='subtitle1'
        color={'primary'}
        fontWeight={700}
        mt={theme.spacing(2)}
        fontSize={
          isMobile
            ? theme.typography.body2.fontSize
            : theme.typography.subtitle1.fontSize
        }
      >
        {resourcesLabel}
      </Typography>
      {!isMobile && (
        <Grid container p={theme.spacing(2)} gap={2}>
          {recourceTypes.map((item) => (
            <ResourcesItem {...item} downloadDocument={downloadDocument} />
          ))}
        </Grid>
      )}

      {isMobile && (
        <Grid container item xs={12} alignItems={'center'} gap={2}>
          <Grid item xs={6}>
            <form>
              <DropdownInput
                inputId={''}
                items={recourceTypes}
                errorMessage={''}
                control={control}
                register={register('recourceType')}
                placeholder={chooseDocument}
              />
            </form>
          </Grid>
          <Grid item xs={5}>

            <Button
              variant='outlined'
              color='secondary'
              disabled={!recourceTypeValue}
              onClick={() => {
                downloadDocument(recourceTypeValue);
              }}
            >
              <CloudDownloadIcon
                color='secondary'
                sx={{
                  color: recourceTypeValue ? 'secondary' : theme.palette.grey['400'],
                  mr: theme.spacing(1),
                }}
              />

              {uploadDocument.download}
            </Button>

          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Resources;
