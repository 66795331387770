const translations = {
  alerts: {
    profile: {
      updated: 'Profile has been updated',
    },
    company: {
      informationSubmitted: 'Information has been submitted',
    },
    contactForm: {
      contactUpdated: 'Contact updated',
      contactCreated: 'Contact created',
    },
    uploadDocument: {
      documentSubmittedError: `The file couldn’t be uploaded. Make sure you're using a supported file type (PDF, JPEG or PNG) and upload up to 5MB (the file size limit for a single document uploaded is 5MB).`,
      documentSubmitted:
        'Thank you for submitting your documents. Once verified this section of your profile will be marked complete.',
    },
    interviews: {
      interviewCancelled: 'Interview cancelled successfully',
      interviewDeclined: 'Interview was successfully declined',
      interviewUpdated: 'Interview was successfully updated',
      interviewBooked: 'Interview has been booked successfully',
      availabilityUpdated: 'Your availability has been updated',
    },
    placements: {
      placementCreated: 'Placement created successfully',
      placementUpdated: 'Placement updated',
      placementSubmitted: 'Placement submitted',
    },
    programs: {
      arrivalInfoCreated: 'Arrival info was successfully created',
      arrivalInfoUpdated: 'Arrival info was successfully updated',
      housingStatusUpdated: 'Status updated',
    },
    authentication: {
      signedIn: 'Signed in',
    },
  },
  loginPage: {
    loginTitle: 'Login',
    loginSubtitle: 'Please enter your details:',
    rememberMe: 'Remember me',
    signInButton: 'Sign In',
    forgotPassword: 'Forgot password?',
  },
  recoverPasswordPage: {
    recoverPasswordTitle: 'Reset Password',
    recoverPasswordSubtitle:
      'Please check your inbox and click in the received link to reset your password',
    recoverPasswordButton: 'Send',
  },
  emailHasBeenSentPage: {
    emailHasBeenSentTitle: 'Alliance Strategies',
    emailHasBeenSentSubtitle:
      'Please check your inbox and click in the received link to reset your password',
    goToLogin: 'Go to login',
    didNotReceiveTheLink: "Didn't receive the link?",
    resend: 'Resend',
  },
  changePasswordPage: {
    changePasswordTitle: 'Create new password',
    changePasswordSubtitle:
      'Your new password must be different from previous used password',
    newPassword: 'New Password',
    confirmPassword: 'Confirm Password',
    changePasswordButton: 'Change password',
    newPasswordInputId: 'newPassword',
    passwordConfirmInputId: 'passwordConfirm',
  },
  createPlacement: {
    program: 'Program *',
    programType: 'Program Type *',
    totalPositions: 'Total Positions *',
    positionDescription: 'Position Description *',
    submitPlacement: 'Submit Placement',
  },
  employmentSection: {
    employmentSectionTitle: 'Employment Position Information',
    program: 'Program *',
    programId: 'program',
    totalPositions: 'Total Positions *',
    totalPositionsId: 'totalPositions',
    selfArranged: 'Self Arranged *',
    selfArrangedId: 'selfArranged',
    positionTitle: 'Position Title *',
    positionTitleId: 'positionTitle',
    supervisor: 'Supervisor *',
    supervisorId: 'supervisor',
    department: 'Department',
    departmentId: 'department',
    englishLevel: 'English Level',
    englishLevelId: 'englishLevel',
    startDate: 'Start Date *',
    startDateId: 'startDate',
    latestStartDate: 'Latest Start Date *',
    latestStartDateId: 'latestStartDate',
    endDate: 'End Date *',
    endDateId: 'endDate',
    latestEndDate: 'Latest End Date *',
    latestEndDateId: 'latestEndDate',
    positionDescription: 'Position Description *',
    positionDescriptionId: 'positionDescription',
    scheduleDescription: 'Schedule Description',
    scheduleDescriptionId: 'scheduleDescription',
    requiredSkills: 'Required Skills',
    requiredSkillsId: 'requiredSkills',
    requiredExperience: 'Required Experience',
    requiredExperienceId: 'requiredExperience',
    specialRequirements: 'Special Requirements',
    specialRequirementsId: 'specialRequirements',
  },
  paymentSection: {
    paymentSectionTitle: 'Payment',
    grossWage: 'Guaranteed salary/wage before deduction in $ *',
    wagePer: 'Guaranteed salary/wage per *',
    wagePerId: 'wagePer',
    averageHoursPerWeek: 'Average hours per week',
    estimatedTip: 'Estimated tip',
    paymentSchedule: 'Payment schedule *',
    paymentScheduleId: 'paymentSchedule',
    allowancesBonusesIncentives: 'Allowances, bonuses and/or incentives',
    allowancesBonusesIncentivesId: 'allowancesBonusesIncentives',
    additionalInformation: 'Additional information',
    additionalInformationId: 'additionalInformation',
    isOvertimeAvailable: 'Is overtime available',
    overtimeWage: 'Overtime wage',
    overtimeWageId: 'overtimeWage',
  },
  trainingOperationSection: {
    trainingSectionTitle: 'Training/Orientation',
    trainingProvided: 'Training/orientation provided',
    trainingDescription: 'Training/orientation description',
    trainingEvPaid: 'EV paid during Training/Orientation',
    trainingPayRate: 'Pay rate during training/orientation',
    trainingLength: 'Length of training/orientation',
    trainingEVIncurCost: 'Will EV incur a cost for Training/Orientation',
    trainingProvidedCost: 'Training/orientation provided cost',
    trainingCostDue: 'Training/orientation cost due',
  },
  groomingSection: {
    dressCode: 'Dress code',
    uniformProvided: 'Uniform provided',
    uniformProvidedId: 'uniformProvided',
    uniformCost: 'Uniform cost',
    dressCodeId: 'dressCode',
    uniformCostDue: 'Uniform cost due',
  },
  documentsSection: {
    documents: 'Documents',
    documentsSignatureRequired: 'Signature Required?',
    documentsDescription: 'Description',
    uploadFile: 'Upload File',
    uploadButton: 'Upload',
    documentColumn: 'Document',
    descriptionColumn: 'Description',
    signatureColumn: 'Signature Required',
    noDocuments: 'No documents',
  },
  fileUpload: {
    chooseFile: 'Choose File',
  },
  interviewsSection: {
    interviewsSectionTitle: 'Interviews',
    sponsorId: 'sponsorInterview',
    sponsor: 'Sponsor Interview Required *',
    hostCompanyId: 'hostCompany',
    hostCompany: 'Host Company Interview Required *',
    applicationVideoId: 'applicationVideo',
    applicationVideo: 'Application Video Required *',
  },
  arrivalInstructionsSection: {
    title: 'Arrival Instructions',
    noDataFoundDescription: 'No data found',
    description:
      'Please choose the relevant arrival instructions from the list below, click the arrow to view full instruction details',
    tableHeader: [
      'Port of entry airport',
      'Should contact before arrival',
      'Is pick up provided',
      'Preferred arrival days',
      'Preferred arrival times',
      'Reporting to',
    ],
    subContentTitles: {
      title: 'Additonal Details',
      nearestAirport: 'Nearest Airport co company',
      transportPickUpCost: 'Transportation pick up cost $',
      returnUponArrival: 'Return to upon arrival',
      contactPhone: 'Contact phone number',
      generalInstructions: 'General arrival instructions',
    },
  },
  housing: {
    title: 'Housing',
    housingLabel: 'Housing',
    genderLabel: 'Gender Requirement',
    housingTable: {
      name: 'Name',
      address: 'Address',
      city: 'City',
      zip: 'Zip',
    },
  },
  placementsList: {
    title: 'Placements',
    createPlacement: 'Create Placement',
    placementsFilters: {
      programs: 'Programs',
      positions: 'Positions',
      statuses: 'Statuses',
      approvedDate: 'Approved Date',
      applyFilter: 'Apply Filter',
      clear: 'Clear',
      showOnlyMatched: 'Show only matched',
      showOnlyNotConfirmed: 'Show only not confirmed',
      search: 'Search table',
    },
    placementTable: {
      buttons: {
        submit: 'Submit',
        confirm: 'Confirm',
        review: 'Review',
      },
      columns: {
        program: 'Program',
        status: 'Status',
        jobId: 'Placement Id',
        position: 'Position',
        department: 'Department',
        starts: 'Starts',
        latestStarts: 'Latest Starts',
        ends: 'Ends',
        latestEnds: 'Latest Ends',
        total: 'Total',
        open: 'Open',
        matched: 'Matched',
        approved: 'Approved',
        actions: 'Actions',
      },
      emptyResult:
        'Sorry, no results were found, please change your filters or search and try again',
      confirmation: {
        confirmed: 'Confirmed',
        pendingConfirmation: 'Pending Confirmation',
      },
    },
    actions: {
      filters: 'Filters',
      clone: 'Clone',
      download: 'Download',
      edit: 'Edit',
      clearFilters: 'Clear Filters',
      applyFilters: 'Apply Filters',
    },
  },
  clonePage: {
    clonePlacementPageHeader: 'Cloning placement #',
    checkbox:
      'By clicking here, I attest that I have reviewed each section of the offer letter and confirm that the additional information provided including but not limited to uniform costs, bonuses and/or incentives, drug testing, airport pick up costs, housing and transportation, and social security support are correct.',
  },
  applicants: {
    title: 'Search Applicants',
    notAllowed: ' is not available for "Host School".',
    applicantsFilters: {
      programs: 'Program',
      languageLevel: 'Language Level',
      skills: 'Skills',
      availableFrom: 'Available From',
      availableTo: 'Available To',
      availableAtShortNotice: 'Available at short notice',
      programType: 'ITP Program Type',
      placementCategory: 'ITP Placement Category',
      residenceCountry: 'Residence Country',
      applyFilter: 'Apply Filter',
      clear: 'Clear',
    },
    actions: {
      filters: 'Filters',
      clearFilters: 'Clear Filters',
      applyFilters: 'Apply Filters',
    },
    switchButtons: {
      tileView: 'Tile View',
      listView: 'List View',
    },
    applicantsTable: {
      buttonsActions: {
        interview: 'Interview',
        resume: 'Resume',
        video: 'Video',
      },
      columns: {
        id: 'Applicant Id',
        name: 'Applicant Name',
        dateOfBirth: 'Date of Birth',
        gender: 'Gender',
        country: 'Country of Residence',
        actions: '',
      },
    },
  },
  tileComponent: {
    interview: 'Interview',
    downloadPDF: 'Download PDF',
  },
  participants: {
    tabName: 'Participants',
    filters: {
      programs: 'Program',
      host: 'Host',
      position: 'Position',
      country: 'Country of Residence',
      statuses: 'Status code',
      startDate: 'Starts after',
      endDate: 'Ends before',
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      applicantId: 'Applicant ID',
    },
    participantsTable: {
      columns: {
        hostEntity: 'Host Entity',
        program: 'Program/Season',
        id: 'Applicant ID',
        ds2019: 'DS-2019 Number',
        firstName: 'Applicant First Name',
        lastName: 'Applicant Last Name',
        email: 'Email',
        dateOfBirth: 'Date of Birth',
        gender: 'Gender',
        statusCode: 'Status Code',
        skypeId: 'Skype ID',
        countryOfResidence: 'Country of Residence',
        jobId: 'Placement ID',
        position: 'Position Title',
        programStartDate: 'Program Start date',
        programEndDate: 'Program End date',
      },
    },
    modal: {
      chooseDocument: 'Choose a document',
      title: 'EV Basic Information',
      applicantID: 'Applicant ID',
      genderLabel: 'Gender',
      ageLabel: 'Age',
      dateOfBirthLabel: 'Date of Birth',
      countryLabel: 'Country of Legal Residence',
      jobOrderIDLabel: 'Placement Order ID',
      programStartDateLabel: 'Program Start Date',
      programEndDateLabel: 'Program End Date',
      emergencyContactLabel: 'Emergency Contact Details',
      emailLabel: 'Email Address',
      skypeLabel: 'Skype ID',
      positionLabel: 'Position Title',
      resourcesLabel: 'Resources',
      resumeLabel: 'Resume',
      applicationResumeLabel: 'Application Resume',
      signedJobOfferLabel: 'Signed Placement Offer',
      signedPlacementPlan: 'Signed Placement Plan',
      chooseDocumentLabel: 'Choose a document',
    },
  },
  visaArrivalInfo: {
    tabName: 'Visa and arrival information',
    filters: {
      programs: 'Program',
      host: 'Host',
      email: 'Email',
      position: 'Position',
      placementId: 'Placement ID',
      embassyDate: 'Embassy after',
      arrivalAirport: 'Arrival Airport',
      airline: 'Airline',
      flightNumber: 'Flight Number',
      arrivalDate: 'Arrival  before',
      applicantId: 'Applicant ID',
    },
    visaArrivalInfoTable: {
      columns: {
        hostEntity: 'Host Entity',
        program: 'Program',
        applicantId: 'Applicant ID',
        applicantFirstName: 'Applicant First Name',
        applicantLastName: 'Applicant Last Name',
        email: 'Email',
        dateOfBirth: 'Date of Birth',
        gender: 'Gender',
        statusCode: 'Status Code',
        countryOfResidence: 'Country of Residence',
        jobId: 'Placement ID',
        position: 'Position Title',
        programStartDate: 'Program Start date',
        programEndDate: 'Program End date',
        embassyDate: 'Embassy Date',
        visaApproved: 'Visa Approved',
        embassy: 'Embassy',
        visaExpirationDate: 'Visa Expiration Date',
        arrivalDate: 'Arrival Date',
        arrivalTime: 'Arrival Time',
        arrivalAirport: 'Arrival Airport',
        airline: 'Airline',
        flightNumber: 'Flight Number',
        additionalInfo: 'Additional Info',
      },
    },
  },
  companyCompleteInfo: {
    title: 'Complete Company Information',
    details: {
      title: 'Company Details',
      videoEmbedLabel: 'Video embed code - website',
      sectorLabel: 'Sector',
      industryLabel: 'Industry',
      orientationRequiredLabel: 'Orientation Required',
      yearEstablishedLabel: 'Year Established',
      fullTimeEmployeeLabel: 'Number of Employees (not including EV)',
      visitorsLabel: 'Number of EV Host Entity hosts annually if any',
      summerWorkLabel: 'Summer Work Travel Program',
      internProgramLabel: 'Intern and Trainee Programs',
      teacherProgramLabel: 'Teacher Program',
      urlLabel: 'URL',
      irsTaxNumberLabel: 'IRS Tax Number',
      siteRequirementLabel: 'Site Visit Requirement Satisfied',
      annualRevenueLabel: 'Approximate annual revenue of the previous year',
      expirationDateLabel: 'Policy Expiration Date',
      workmanCompIdLabel: `Workman's Comp Policy ID`,
      workmanCompPhoneLabel: `Workman's Comp Policy Provider Phone`,
      insuranceCompany: 'Insurance Company',
      descriptionLabel: 'Company Description',
      commentsLabel: 'Comments',
      businessLicenseLabel:
        'Business License (or Host School Accreditation) expiration date',
      termsConditionsExpirationLabel: 'Terms & Conditions expiration date',
      seasonalityReportExpirationLabel: 'Seasonality Report expiration date',
      hostAddressLabel: 'Host Entity Head Quarters address (including county)',
      hostIncorporateInUsaLabel: 'Is the Host Entity incorporated in the USA?',
      hostIncorporateNotUsaLabel: 'If no, where is Host Entity incorporated?',
      layoffsLabel: 'Have you had any layoffs in the past 120 days?',
      otherSponsorsLabel:
        'Which other sponsors are you currently working with?',
      howLongHiringParticipantsLabel:
        'How long have you been hiring participants for the J1 programs?',
    },
    program: {
      title: 'Program(s)',
      address:
        'Which address will the Exchange Visitor(s) be working/training at? Is the site based out of a home/residence?',
      hasSupervisor: 'Will there always be a supervisor on-site?',
      isEnglishPrimaryLanguage:
        'Will English be the primary language used during the program(s)?',
      hasHelpWithCulturalActivities:
        'Will Host Entity assist with organizing cultural activities for the Exchange Visitor(s)?',
      isProvided32HoursPerWeek:
        'Will Host Entity provide at least 32 hours a week (not an average of)?',
      isTrainingOrWorkOverNightRequired:
        'Will Host Entity require Exchange Visitor(s) to train/work overnight?',
      isHousingProvided: 'Will Host Entity provide housing?',
      aproximateCostOfHousingPerMonthPerExchangeVisitor:
        'The approximate cost of the housing per month per Exchange Visitor:',
      existsAnyAffordableHousingOptionsNearby:
        'Is there safe, affordable housing close to the training/worksite(s)?',
      hasAnyOtherVisaPrograms:
        'Is the Host Entity utilizing any other visa programs currently? (i.e.: H-1B, H-2B, L, F-1, etc.)?',
      otherVisaProgramsDescription: 'Please describe the other visa programs:',
    },
  },
  companyHousing: {
    title: 'Housing',
    subtitle:
      'You can add new housing profiles and edit them until they are ready. When you are done, press the "Activate" button to make the housing profile available on Offer Letters. You will not be able to edit housing profiles after they are activated. However, you will be able to change the status of housing profiles at any time by using the "Change to Inactive" button. Housing profiles that are inactive will not show on Offer Letters.',
    addNewHousing: 'Add new housing',
    companyHousingTable: {
      columns: {
        name: 'Name',
        status: 'Status',
        address: 'Address 1',
        city: 'City',
        zip: 'Zip',
        actions: 'Actions',
      },
    },
    statusButtons: {
      active: 'Activate',
      inactive: 'Change to inactive',
    },
    edit: 'Edit',
  },
  housingForm: {
    titleEdit: 'Edit Housing #:housingId',
    titleCreate: 'Create Housing',
    sections: {
      details: {
        title: 'Details',
        housingModel: 'Housing model*',
        housingType: 'Housing type*',
        propertyName: 'Property name*',
        contactName: 'Contact name*',
        propertyManagement: 'Property management',
        canBeCoEd: 'Can be co ed',
        phoneNumber: 'Phone number',
        website: 'Website',
        emailForReservationEnquiries: 'Email for reservation enquiries',
        description: 'Description',
        addressStreet: 'Address street*',
        addressCity: 'Address city*',
        addressState: 'Address state*',
        addressZipCode: 'Address zip code*',
        numberOfBedsPerRoom: 'Number of beds per room',
        numberOfBedrooms: 'Number of bedrooms',
        participantsPerProperty: 'Participants per property',
        participantsPerRoom: 'Participants per room',
        bathroomsPerProperty: 'Bathrooms per property',
        beddingAndTowels: 'Bedding and towels',
        costOfBeddingAndTowels: 'Cost of bedding and towels',
        beddingAndTowelPaymentDue: 'Bedding and towel payment due',
        kitchenFacilities: 'Kitchen facilities',
        additionalItemsMustBring: 'Additional items must bring',
        additionalComments: 'Additional comments',
        housingCost: 'Housing cost*',
        rentPeriodicity: 'Rent periodicity*',
        isWeeklyRentPayrollDeducted: 'Is weekly rent payroll deducted*',
        includedInCost: 'Included in cost',
        additionalItemsIncludedInCost: 'Additional items included in cost',
        administrationFee: 'Administration fee',
        administrationFeeDue: 'Administration fee due ?',
        housingDepositAmount: 'Housing deposit amount*',
        isHousingDepositPayrollDeducted: 'Is housing deposit payroll deducted*',
        housingDepositRefundable: 'Housing deposit refundable*',
        furtherInformationOnHousingRefundPolicy:
          'Further information on housing refund policy',
        leaseRequired: 'Lease required',
        lengthOfLease: 'Length of lease',
        timeUnit: 'Time unit',
        furtherInformationOnLengthOfLease:
          'Further information on length of lease',
        feesAdditionalComments: 'Fees additional comments',
        depositRefundPolicy: 'Deposit refund policy',
        isRentersInsuranceRequired: 'Is renters insurance required',
        housingAmentities: 'Housing amentities',
        distanceFromWorkSiteToHousing: 'Distance from work site to housing',
        transportationDetails: 'Transportation details',
      },
      utilities: {
        title: 'Utilities',
        gas: 'Gas',
        electric: 'Electric',
        water: 'Water',
        sewer: 'Sewer',
        trash: 'Trash',
        internet: 'Internet',
        tv: 'Tv',
        telephone: 'Telephone',
        isThePropertyAbleOrWillingToSetUtilitiesUpForOurParticipants:
          'Is the property able/willing to set utilities up for our participants?',
        whatIsTheAverageMonthlyCostOfAnyUtilitiesNotIncluded:
          'What is the average monthly cost of any utilities not included?',
      },
      security: {
        title: 'Security',
        gated: 'Gated',
        areThereActiveSecurityCamerasOnSite:
          'Are there active security cameras on site',
        secured: 'Secured',
        isThereARepresentativeOnSite24For7:
          'Is there a representative on site 24/7',
        contactName: 'Contact name',
        contactPhone: 'Contact phone',
        contactEmail: 'Contact email',
        bus: 'Bus',
        frequencyOfBus: 'Frequency of bus',
        subway: 'Subway',
        frequencyOfSubway: 'Frequency of subway',
        train: 'Train',
        frequencyOfTrain: 'Frequency of train',
        otherPublicTransport: 'Other public transport',
        isThereAWebsiteForPublicTransportation:
          'Is there a website for public transportation?',
        isItConsideredSafeToWalkToTheNearestPublicTransportationStop:
          'Is it considered safe to walk to the nearest public transportation stop?',
        howFarIsIt: 'How far is it?',
        doesItRunToTheJobsite: 'Does it run to the jobsite',
      },
    },
  },
  offerDetailsForm: {
    titleCreate: 'Create Offer Details',
    title: 'Offer Details',
    stepper: {
      screening: 'Screening',
      grooming: 'Grooming',
      housing: 'Housing (Placement Offer)',
      socialSecurity: 'Social Security',
    },
    sections: {
      screening: {
        title: 'Screening',
        will_ev_incur_a_cost_for_screening:
          'Will EV incur a cost for screening *',
        will_company_require_a_drug_test: 'Will company require a drug test? *',
        drug_screening_policy: 'Drug screening policy *',
        drug_test_provided_by_co: 'Is drug test provided by host company *',
        drug_screening_cost_to_student: 'Drug screening cost to student(s) *',
        drug_screening_cost_due: 'Drug screening cost due *',
      },
      grooming: {
        title: 'Grooming',
        grooming_standards: 'Grooming standards *',
      },
      housing: {
        title: 'Housing (Placement Offer)',
        provide_temporary_housing:
          'Does Host Entity provide temporary housing? *',
        duration_of_temporary_housing: 'Duration of temporary housing',
        cost_of_temporary_housing: 'Cost of temporary housing *',
      },
      socialSecurity: {
        title: 'Social Security',
        nearest_social_security_office:
          'Address of the nearest social security office *',
        distance_of_sso_from_soa: 'Distance of SSO from SOA',
        host_entity_provide_transportation:
          'Will Host Entity provide transportation to the nearest Social Security office *',
        host_entity_help:
          'Will Host Entity help EV make copies of the necessary documents *',
        other_social_security_assistance:
          'Other Social Security assistance provided *',
      },
    },
  },
  uploadDocument: {
    title: 'Upload',
    cancelBtn: 'Cancel',
    uploadBtn: 'Upload',
    dragAndDropMessage: 'Drag and drop to upload',
    or: 'or',
    browse: 'browse',
    previousDocument: 'The previous document that has been rejected:',
    download: 'Download',
    confirmation:
      'I confirm that my document meets the criteria outlined above and understand that it will be rejected if it does not.',
    changeDocument: 'Change Document',
    withoutPreview:
      'The selected file is too big to be previewed, however it can still be uploaded.',
    notAllowedPage: 'This document',
    notAllowed: ' the document list.',
  },
  menu: {
    home: 'Home',
    searchApplicants: 'Search Applicants',
    interviews: 'Interviews',
    participants: 'Participants',
    placements: 'Placements',
    company: 'Company',
    programs: 'Programs',
    settings: 'Settings',
    programResources: 'Program resources',
  },
  companySubMenu: {
    companyInfo: 'Company Information',
    contacts: 'Contacts',
    adresses: 'Addresses',
    documents: 'Documents',
  },
  programsSubMenu: {
    programInfo: 'Program Information',
    arrivalInstuctions: 'Arrival Instructions',
    housing: 'Housing',
    offerDetails: 'Offer Details',
  },
  documentList: {
    linksRemarks: { more: 'more', less: 'less' },
    title: 'Documents',
    buttonUploadLabel: 'Upload',
    buttonDownloadLabel: 'Download',
    columns: {
      documentTypeLabel: 'Document Type',
      statusLabel: 'Status',
      remarksLabel: 'Remarks',
      isRequiredLabel: 'Required',
      actionsLabel: 'Action',
    },
  },
  tables: {
    noDataMessageDefault: 'Sorry, no results were found.',
  },
  hostAddress: {
    title: 'Complete Addresses information',
    subtitle:
      'If you need to update this information, please reach out to your Account Manager.',
    commonLabes: {
      address1: 'Address 1 *',
      address2: 'Address 2',
      city: 'City *',
      state: 'State',
      zipCode: 'Zip *',
      country: 'Country *',
      phone: { countryCode: 'Country Code', number: 'Phone Number' },
    },
    physicalAddress: {
      title: 'Physical Address',
    },
    mailingAddress: {
      title: 'Mailing Address',
    },
  },
  arrivalInformation: {
    header: 'Arrival Information',
    addNew: 'Add New Arrival Information',
    tableHeaders: {
      status: 'Status',
      description: 'Description',
      airport: 'Nearest international airport',
      pickUpProvided: 'Pick up provided',
      shouldContactBeforeArrival: 'Should contact HC before arrival',
      phone: 'Contact phone number',
      pickUpCost: 'Transport pick up cost',
      arrivalDays: 'Preferred arrival days',
      arrivalTimes: 'Preferred arrival times',
      report: 'Reporting to',
      actions: 'Actions',
    },
    status: {
      locked: 'Locked',
      unlocked: 'Unlocked',
      changeRequested: 'Change Requested',
    },
    requestedChanges: 'Requested changes:',
    form: {
      title: 'New Employers Arrival Info',
      editTitle: 'Edit Employers Arrival Info',
      editSubtitle:
        'Your request will need to be approved by your account manager before being active.',
      createArrivalInfoBtn: 'Create Arrival Info',
      saveArrivalInfoBtn: 'Save Arrival Info',
      program: 'Program *',
      programId: 'program',
      nearestInternationalAirport: 'Nearest international airport',
      nearestInternationalAirportId: 'nearestInternationalAirport',
      nearestAirportToJobSite: 'Nearest airport to placement site',
      nearestAirportToJobSiteId: 'nearestAirportToJobSite',
      isPickUpProvided: 'Is pick-up provided?',
      isPickUpProvidedId: 'isPickUpProvided',
      latestPreferredArrivalDate: 'Latest preferred arrival date',
      latestPreferredArrivalDateId: 'latestPreferredArrivalDate',
      generalArrivalInstructions: 'General arrival instructions',
      generalArrivalInstructionsId: 'generalArrivalInstructions',
      shouldContactHCBeforeArrival: 'Should Contact HC Before Arrival?',
      shouldContactHCBeforeArrivalId: 'shouldContactHCBeforeArrival',
      contactPhoneNumber: 'Contact Phone Number',
      contactPhoneNumberId: 'contactPhoneNumber',
      transportPickUpCost: 'Transport pick-up cost',
      transportPickUpCostId: 'transportPickUpCost',
      preferredArrivalDays: 'Preferred arrival days',
      preferredArrivalDaysId: 'Preferred arrival days',
      preferredArrivalTimes: 'Preferred arrival times',
      preferredArrivalTimesId: 'preferredArrivalTimes',
      reportingTo: 'Reporting to',
      reportingToId: 'reportingTo',
      description: 'Description',
      descriptionId: 'description',
      notAllowedPage: 'This arrival instruction',
      notAllowed: ' arrival instructions list.',
    },
  },
  currPrevValueTableCell: {
    yes: 'Yes',
    no: 'No',
    empty: 'N/A',
  },
  contacts: {
    title: 'Contacts For',
    editButtonTooltip: 'Edit contact',
    addButtonLabel: 'Add Contact',
    table: {
      header: {
        typeLabel: 'Type',
        nameLabel: 'Name',
        emailLabel: 'Email',
        directPhoneLabel: 'Phone 1 (Direct Line)',
        mobilePhoneLabel: 'Phone 2 (Mobile)',
        positionLabel: 'Position',
        faxLabel: 'Fax',
        actionLabel: '',
      },
    },
    form: {
      createTitle: 'Create Contact',
      editTitle: 'Edit Contact',

      name: 'Name*',
      email: 'Email*',
      directPhoneNotRequired: 'Phone 1 (Direct Line)',
      directPhoneRequired: 'Phone 1 (Direct Line)*',
      mobilePhone: 'Phone 2 (Mobile)',
      title: 'Title',
      department: 'Department',
      fax: 'Fax',
      type: 'Type*',
      skype: 'Skype',
    },
  },
  email: 'Email',
  password: 'Password',
  returnToLogin: 'Return to login',
  newPasswordMinErrorMsg: 'The new password must be at least 8 characters',
  newPasswordMaxErrorMsg:
    'The new password may not be longer than 16 characters',
  confirmPasswordErrorMsg: 'Passwords do not match',
  passwordInputId: 'password',
  appBarSearchPlaceholder: 'Search',
  notAllowed:
    ' is not available for view/edit. Please press Cancel to return to',
  lockedMenu: 'This segment has been locked',
  changeHostText: 'UX Project - Test Host Entity longer than this very',
  changeHostButton: 'Change host',
  createPlacementPageHeader: 'Create Placement',
  cancelButton: 'Cancel',
  continueButton: 'Continue',
  saveButton: 'Save',
  submitButton: 'Submit',
  editPlacementPageHeader: 'Edit Placement',
  dropdownYesValue: 'Yes',
  dropdownNoValue: 'No',
  dropdownSelectAll: 'Select All',
  responsiveListView: {
    mobileFiltersBar: {
      filterButton: 'Filters',
    },
  },
  allInterviews: {
    tabName: 'All Interviews',
    table: {
      columns: {
        hostEntity: 'Host Entity',
        program: 'Program',
        applicantId: 'Applicant ID',
        applicantName: 'Applicant Name',
        email: 'Email',
        applicationStatus: 'Status',
        skype: 'Skype',
        countryOfResidence: 'Country of Residence',
        jobId: 'Placement Id',
        position: 'Position',
        placementStartsOn: 'Starts On',
        placementEndsOn: 'Ends On',
        interviewDate: 'Interview Date',
        interviewStatus: 'Interview Status',
        interviewer: 'Interviewer',
        interviewerSkypeId: 'Interview Link / Instructions',
        actions: '',
        changeTime: 'Change Time',
        cancelInterview: 'Cancel Interview',
      },
    },
    actions: {
      changeTime: 'Change time',
      interviewReport: 'Interview Report',
      approve: 'Approve / Hire Candidate',
      noShow: 'No Show',
      noHired: 'Not Hired',
      decline: 'Decline Interview',
    },
    modal: {
      areYouSure: 'Are you sure you want to proceed with this action?',
      sorryToDecline:
        'We are sorry that you want to decline this interview after reviewing the resume of the candidate. Please provide us with more details below (these notes will not be shared with the candidate).',
      jobPositionLabel: 'Position: ',
      participantNameLabel: 'Participant Name: ',
      selectDeclineReason: 'Select decline reason',
      interviewReport: {
        overallEnglishLevel: 'Overall English Level *',
        furtherComments: 'Further Comments *',
        interviewerSignature: 'Interviewer Signature *',
        interviewDate: 'Interview Date *',
      },
    },
  },
  interviewsToBook: {
    tabName: 'Interviews To Book',
    interviewsToBookTable: {
      columns: {
        hostEntity: 'Host Entity',
        program: 'Program',
        applicantId: 'Applicant ID',
        applicantName: 'Applicant Name',
        email: 'Email',
        status: 'Status',
        skype: 'Skype',
        countryOfResidence: 'Country of Residence',
        jobId: 'Placement ID',
        position: 'Position',
        startsOn: 'Starts On',
        endsOn: 'Ends On',
        actions: '',
      },
      buttonsActions: {
        interview: 'Book Interview',
        decline: 'Decline Interview',
      },
    },
  },
  availability: {
    buttonLabel: 'Set my Availability',
    setAvailability: {
      title: 'Update your availability',
      stepOne: 'STEP ONE',
      stepTwo: 'STEP TWO',
      selectWeek: 'Select Week',
      setNewHours: 'Set new hours',
      unavailable: 'Unavailable',
      multipleTimesSelected: 'Multiple times selected',
      copyAvailability: 'Copy availability',
    },
  },
  scheduleInterview: {
    title: 'Schedule interview',
    chooseAJob: 'Choose a Placement',
    selectDay: 'Select Day',
    chooseAtime: 'Choose a time',
    selectADayFirst: 'Please select a day first',
  },
  modal: {
    buttons: {
      cancel: 'Cancel',
      save: 'Save',
      submit: 'Submit',
      confirm: 'Confirm',
      yes: 'Yes',
      no: 'No',
    },
  },
  home: {
    interviewsWidget: {
      title: 'Interviews',
      programsFilter: 'Programs',
      tabs: {
        interviewsToBeScheduled: 'Interviews To be Scheduled (with_total)',
        interviewsPendingResults: 'Interviews Pending Results (with_total)',
        upcomingInterviews: 'Upcoming interviews (with_total)',
      },
      viewAll: 'View All',
      interview: 'Interview',
    },
    tasksWidget: {
      title: 'Tasks',
      confirm: 'Confirm',
      placementChip: 'Placement',
      view: 'View',
      tippChip: 'TIPP',
      pendingSignature: 'Pending Signature of',
      details: 'Details',
      participantName: 'Participant Name: ',
      participantId: 'Participant ID: ',
      program: 'Program: ',
      positionTitle: 'Position Title: ',
      placementId: 'Placement ID: ',
      host: 'Host: ',
    },
    scheduleWidget: {
      today: 'Today',
      joinToZoom: 'Join',
      interviewWith: 'Interview with',
      now: 'Now',
      comingUp: 'Coming up',
      viewAllInterviews: 'View All Interviews',
      setAvailability: 'Set my Availability',
    },
    analyticsWidget: {
      interviewsFirstLine: 'Interviews',
      arrivalsFirstLine: 'Arrivals',
      secondLine: 'in the next 7 days',
    },
  },
  previewPlacement: {
    pageTitle: 'Preview placement',
    confirmButton: 'Confirm',
    housingsDocuments: 'Housings Documents',
    jobOfferDocuments: 'Placement Offer Documents',
  },
  profile: {
    pageHeaderTitle: 'Profile',
    timeZone: 'Time Zone',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    skype: 'Skype',
  },
  notifications: {
    title: 'Notifications',
    archive: 'Archive',
    showOnlyArchived: 'Show only Archived',
    columns: {
      dateTime: 'Date/Time',
      title: 'Title',
      content: 'Content',
      createdBy: 'Created by',
    },
    viewAll: 'View all notifications',
  },
  programResources: {
    title: 'Program Resources',
    link: 'Access link',
    download: 'Download file',
    cells: {
      link: 'Download / Access Link',
      program: 'Programme',
      description: 'Description',
    },
  },
};

export default translations;
